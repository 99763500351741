import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'logon',
      component: () => import('../views/logon.vue')
    },
    {
      path: '/jFIndex',
      name: 'jFIndex',
      component: () => import('../views/jFIndex.vue')
    },
    {
      path: '/incubate',
      name: 'incubate',
      component: () => import('../views/incubate.vue')
    },
    {
      path: '/mass',
      name: 'mass',
      component: () => import('../views/mass.vue')
    },
    {
      path:'/capacity',
      name:'capacity',
      component:()=> import('../views/capacity.vue')
    },
    {
      path:'/swiper',
      name:'swiper',
      component:()=> import('../views/swiper.vue')
    }
  ]
  const router = new VueRouter({
    routes
  })
export default router